@import "../../node_modules/ag-grid-community/src/styles/ag-grid.scss";
@import "../../node_modules/ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham.scss";

$odd-row-background-color: #2b2e30;

.img-news-max-height {
  max-height: 60px;
}

@media (max-width: 768px) { /* For smaller screens */
  .img-news-max-height {
      max-height: 200px;
  }
}

.custom-gradient-anyday-title {
  background: linear-gradient(to right, lightsteelblue, #cddff8); 
}

.btn-xxs {
  padding: 2px 6px;
  font-size: 0.75rem;
  line-height: 1;
}



.searchSmall {
  border-radius: 5px;
  width: 200px;
  padding-left: 7px;
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1px solid #c6c0c0;
  font-size: 16px;
}

.badge.larger-badge {
  font-size: 1.1em;
}

.top-right-corner {
  //background-color: #007bff;
  //color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}

.search-container {
  height: 32px;
  width: 30px;
  padding: 0px 12px 0px 12px;
  }

.timeline-break {
  font-size: 40px;
  font-weight: bold;
  color: rgb(194, 190, 190);
}

.border-10 {
  border: 2px solid rgb(194, 190, 190);
   padding: 10px;
  border-radius: 25px;
  }

.border-top-10 {
  border-top: 2px solid rgb(194, 190, 190);
  //padding: 10px;
  //border-radius: 25px;
  }

.border-left-10 {
  border-left: 2px solid rgb(194, 190, 190);
  //padding: 10px;
  //border-radius: 25px;
  }

.border-right-10 {
  border-right: 2px solid rgb(194, 190, 190);
  //padding: 10px;
  //border-radius: 25px;
  }

.border-bottom-10 {
  border-bottom: 2px solid rgb(194, 190, 190);
  //padding: 10px;
  //border-radius: 25px;
  }

.selectAnyDay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.selectAnyDay select {
  background-color: #0563af;
  color: white;
  padding: 12px;
  width: 250px;
  border: none;
  font-size: 20px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -webkit-appearance: button;
  appearance: button;
  outline: none;
}

.selectAnyDay::before {
  content: "\f13a";
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
  text-align: center;
  font-size: 28px;
  line-height: 45px;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.selectAnyDay:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}

.selectAnyDay select option {
  padding: 30px;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}


.aligncenter {
    text-align: center;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.custom-control-label::before, 
.custom-control-label::after {
//top: .8rem;
width: 0.75rem;
height: 0.75rem;
}

.YourHighlightClass {
  color: rgb(7, 7, 7);
  background-color: #07f713;
}

.search input {
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 37px;
  width: 255px;
  margin-left: 1px;
  padding: 12px;
}

.searchMedium input {
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 37px;
  width: 210px;
  margin-left: 1px;
  padding: 12px;
}

.searchSmall input {
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 37px;
  width: 185px;
  margin-left: 1px;
  padding: 12px;
}

.Date {
  font-size: 13px;
  background: rgb(229, 231, 235);
}

.Date15 {
  font-size: 15px;
  background: rgb(229, 231, 235);
}

.Date15Wrap {
  font-size: 15px;
  background: rgb(229, 231, 235);
  white-space: pre-wrap;
}

.DateWrap {
  font-size: 13px;
  background: rgb(229, 231, 235);
  white-space: pre-wrap;
}

.DateInline {
  font-size: 13px;
  background: rgb(229, 231, 235);
  display: inline;
}

.CleanDate {
  font-size: 13px;
}

.DateTxt {
  font-size: 13px;
  background: rgb(229, 231, 235);
  white-space: pre-wrap;
}

.SubTitle {
  font-size: 16px;
  font-style: italic;
  background: rgb(229, 231, 235);
}

.select {
  //font-family: "Times New Roman";
  font-size: 14px;
}

.btn-group-xs > .btn, .btn-xs {
  padding: .45rem .3rem;
  font-size: .9rem;
  line-height: .7;
  border-radius: .4rem;
}

.btn-group-xs > .btn, .btn-xxs {
  padding: .25rem .4rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}

.rounded-xl {
  border-radius: .9rem;
}


.featured {
  background: rgb(253, 253, 211);
}

.Number {
  background-color: #e3e3e3;
  font-size: 20px;
  font-weight: bold;
}

.ReducedText {
  font-size: 14px;
}

.Text14bold {
  font-size: 14px;
  font-weight: bold;
}

.SmallText {
  font-size: 13px;
}

.ConcertTitleLight {
  font-size: 14px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  background: rgb(235, 235, 232);
  //font-style: italic;
}

.ConcertTitleLight2 {
  font-size: 16px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  background: rgb(235, 235, 232);
  //font-style: italic;
}

.videoOfTheDayTitle {
  font-size: 26px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  background: rgb(161, 209, 241);
  //font-style: italic;
}

.bestSongsTitle {
  font-size: 24px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  //background: rgb(201, 236, 193);
  //font-style: italic;
}

.postIntro {
  font-size: 16px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  //background: rgb(201, 236, 193);
  //font-style: italic;
}

.Arial22 {
  font-size: 22px;
  font-family: Arial, Helvetica, sans-serif;
}

.videoTitle {
  font-size: 18px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  background: khaki;
  //font-style: italic;
}

.videoTitle22 {
  font-size: 22px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  background: khaki;
  //font-style: italic;
}

.concertsLists22 {
  font-size: 22px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  background: rgb(240, 242, 242);
  //font-style: italic;
}

.videoTitle2 {
  font-size: 18px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  background: rgb(176, 196, 222);
  //font-style: italic;
}

.TimelineTitleSmall {
  font-size: 15px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  background: rgb(176, 196, 222);
  //font-style: italic;
}

.TimeLineItemTitle {
  font-size: 20px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

.HeaderSongsListsMobileRank {
  font-size: 35px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  //background: rgb(226, 228, 234);
  //font-style: italic;
}
.HeaderSongsListsMobile {
  font-size: 25px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  //background: rgb(226, 228, 234);
  //font-style: italic;
}

.HeaderSongsListsMobileGroup {
  font-size: 25px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  background: rgb(226, 228, 234);
  //font-style: italic;
}

.HeaderSongsListsRank {
  font-size: 40px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  //background: rgb(226, 228, 234);
  //font-style: italic;
}

.HeaderSongsListsMobileYear {
  font-size: 17px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  //background: rgb(226, 228, 234);
  //font-style: italic;
}

.HeaderSongsListsMobileYearGroup {
  font-size: 20px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  background: rgb(226, 228, 234);
  //font-style: italic;
}

.HeaderSongsLists {
  font-size: 35px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  //background: rgb(226, 228, 234);
  //font-style: italic;
}

.HeaderSongsListsGroup {
  font-size: 35px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  background: rgb(226, 228, 234);
  //font-style: italic;
}

.HeaderSongsListsYear {
  font-size: 28px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  //background: rgb(226, 228, 234);
  //font-style: italic;
}

.TitleListSongsLists {
  font-size: 45px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  background: rgb(226, 228, 234);
  //font-style: italic;
}

.TitleListSongsListsMobile {
  font-size: 35px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  background: rgb(226, 228, 234);
  //font-style: italic;
}

.sortTitle {
  font-size: 42px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  background: rgb(226, 228, 234);
  //font-style: italic;
}

.TodayTitle {
  font-size: 22px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  //margin: "20px 0";
  background: wheat;
  //font-style: italic;
}

.AnyDayTitle {
  font-size: 23px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(250, 250, 253);
  //background: wheat;
}

.TodayTitle20 {
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  background: wheat;
}

.TodayTitle24nobg {
  font-size: 24px;
  font-family: Arial, Helvetica, sans-serif;
}

.HeaderCover {
  font-size: 22px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  margin: "30px 0";
  //background: wheat;
  //font-style: italic;
}

.Header {
  font-size: 22px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  //height: 30px;
  margin: "30px 0";
  background: wheat;
  //font-style: italic;
}

.Header2 {
  background-color: #f1efef;
  font-size: 17px;
}

.Header3 {
  //background-color: #f1efef;
  font-size: 22px;
  //font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  font-style: italic;
}

.AlbumSuperslim {
  font-size: 22px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f1efef;
}

.Header4 {
  background-color: #f1efef;
  font-size: 14px;
}

.Description {
  font-size: 13px;
  white-space: pre-wrap;
}

.Description16 {
  font-size: 16px;
  white-space: pre-wrap;
}

.DescriptionBackground {
  background-color: #f1efef;
  font-size: 13px;
  white-space: pre-wrap;
}

.DescriptionSmall {
  font-size: 12px;
  white-space: pre-wrap;
}

.Font14 {
  font-size: 14px;
  //white-space: pre-wrap;
}

.Font14_wrap {
  font-size: 14px;
  white-space: pre-wrap;
}

.Font13 {
  font-size: 13px;
}

.DescriptionBig {
  background-color: #f1efef;
  font-size: 14px;
  white-space: pre-wrap;
}

.DescriptionBig16 {
  background-color: #f1efef;
  font-size: 16px;
  white-space: pre-wrap;
}

.DescriptionBig2 {
  //background-color: #f1efef;
  font-size: 14px;
  white-space: pre-wrap;
}

.lyrics {
  font-size: 15px;
  white-space: pre-wrap;
}

.quote {
  font-size: 14px;
  white-space: pre-wrap;
}

.quote2 {
  font-size: 12px;
  white-space: pre-wrap;
}

.quote3 {
  font-size: 13px;
  white-space: pre-wrap;
}

.quote4 {
  font-size: 15px;
  white-space: pre-wrap;
}

.quote16 {
  font-size: 16px;
  white-space: pre-wrap;
}

.PeopleName {
  background: khaki;
  align: center; 
  width: 150;
}

.Song {
  background: khaki;
}

.Song2 {
  font-size: 13px;
  background: khaki;
}

.BestSong {
  font-size: 20px;
  background: khaki;
}

.AlbumTitle {
  font-size: 20px;
  color: rgb(232, 228, 228)
}

.Dead {
  background: rgb(88, 91, 86);
  color: rgb(232, 228, 228)
}

.Crown {
  background: rgb(200, 248, 168);
}

.Great {
  background: khaki;
}

.important {
  background: khaki;
}

.Good {
  background: lightsteelblue;
}

.todayRest {
  background: lightsteelblue;
}

.factText {
  background-color: #f1efef;
  font-size: 13px;
  white-space: pre-wrap;
}
.factTextMarkdown {
  background-color: #f1efef;
  font-size: 13px;
}

.factText15 {
  background-color: #f1efef;
  font-size: 15px;
  white-space: pre-wrap;
}

.factTextNoWrap {
  background-color: #f1efef;
  font-size: 13px;
}

.factTextNoWrap15 {
  background-color: #f1efef;
  font-size: 15px;
}

.factText2 {
  background-color: #f1efef;
  font-size: 12px;
  white-space: pre-wrap;
}

.factText3 {
  background-color: #f1efef;
  font-size: 10px;
  white-space: pre-wrap;
}

.factText4 {
  background-color: #f8f4f4;
  font-size: 13px;
  white-space: pre-wrap;
}

.factText_green {
  background-color: #8ef77e;
  font-size: 13px;
  white-space: pre-wrap;
}


.Hide {
  display: none;
}

body {
    margin: 100;
  }

table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    border-spacing: 5px;
  }

th, td {
    padding: 5px;
    // text-align: center;
  }

caption {
  font-weight: 900;
  font-size: 18px;
}